import React from 'react'
import './style.scss'
import { Link } from 'gatsby'

const NavLink = ({ children, to }) => (
    <Link to={to} activeClassName="active-link">
        {children}
    </Link>
)

export default NavLink;