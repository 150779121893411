import React from 'react';
import Helmet from '../components/helmet';
import Header from '../components/header';
import Contact from '../components/contact-section';

const ContactPage = () => (
    <section>
        <Helmet />
        <Header />
        <Contact />
    </section>

);

export default ContactPage;
