import React from 'react';
import './style.scss';
import { Link } from 'gatsby';
import logo from '../images/logo-dark.svg'
import NavLink from './nav-link'

const Navbar = () => (
	<div className="is-hidden-mobile topbar" >
		<div className="navbar-menu">
			<div className="navbar-start">
				<Link to="/">
					<img className="logo-type" src={logo} alt="logo" />
				</Link>
			</div>
			<div className="navbar-end nav-link">
				<NavLink to="/case-studies">Case Studies</NavLink>
				<NavLink to="/writings">Writings</NavLink>
				<NavLink to="/resume">Resume</NavLink>
				<NavLink to="/contact">Contact</NavLink>
			</div>
		</div>
	</div>
);

export default Navbar;
