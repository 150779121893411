import React from 'react'
import './style.scss'
import Instagram from '../images/instagram.svg'
import LinkedIn from '../images/linkedin.svg'
import Twitter from '../images/twitter.svg'
import Dribbble from '../images/dribbble.svg'

const Contact = () => (
    <section>
        <div className="columns is-centered">
            <div className="column is-6">
                <div className="page-title-wrap">
                    <span className="title-decoratiion"></span>
                    <h1 className="general-page-title">Contact</h1>
                </div>
            </div>
        </div>
        <div className="contact">
            <div className="columns is-centered">
                <div className="column is-5">
                    <p className="desc">Feel free to reach out for collaborations or just a friendly hello.</p>
                    <div className="contact-text">
                        <p>willy.syafiq@gmail.com</p>
                        <p>(+62)896 3635 4693</p>
                    </div>
                    <div className="social is-hidden-mobile">
                        <ul>
                            <li><a href="https://www.instagram.com/willy.syafiq/" target="_blank" rel="noreferrer">Instagram</a></li>
                            <span className="link-divider">/</span>
                            <li><a href="https://www.linkedin.com/in/willy-ahmad-syafiq-35095610a/" target="_blank" rel="noreferrer">LinkedIn</a></li>
                            <span className="link-divider">/</span>
                            <li><a href="https://twitter.com/willy_as" target="_blank" rel="noreferrer">Twitter</a></li>
                            <span className="link-divider">/</span>
                            <li><a href="https://dribbble.com/willysyafiq" target="_blank" rel="noreferrer">Dribbble</a></li>
                        </ul>
                    </div>
                    <div className="social-icon is-hidden-tablet">
                        <ul>
                            <li>
                                <a href="https://www.instagram.com/willy.syafiq/" target="_blank" rel="noreferrer">
                                    <img src={Instagram} alt="instagram" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/in/willy-ahmad-syafiq-35095610a/" target="_blank" rel="noreferrer">
                                    <img src={LinkedIn} alt="linkedin" />
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/willy_as" target="_blank" rel="noreferrer">
                                    <img src={Twitter} alt="twitter" />
                                </a>
                            </li>
                            <li>
                                <a href="https://dribbble.com/willysyafiq" target="_blank" rel="noreferrer">
                                    <img src={Dribbble} alt="dribbble" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default Contact
