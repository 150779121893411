import React from 'react';
import './style.scss';
import Navbar from './navbar';
import NavbarMobileDark from './navbar-mobile-dark';

const Header = ({ siteTitle }) => (
	<div className="columns is-centered is-gapless">
		<div className="column is-10 is-hidden-mobile">
			<Navbar />
		</div>
		<div className="column is-12 is-hidden-tablet">
			<NavbarMobileDark />
		</div>
	</div>
);

export default Header;
