import React from 'react';
import './style.scss';
import { Link } from 'gatsby';
import Logo from '../images/logo-light.svg'
import MenuIcon from '../images/menu.svg'
import MobileMenu from './mobile-menu'

class NavbarMobileDark extends React.Component {
    render() {
        return (
            <div>
                <nav className="columns is-mobile topbar is-gapless bg-purple">
                    <div className="column is-2">
                        <button className="hamburger-button" onClick={() => this.toggleMenu()}>
                            <img className="hamburger-menu" src={MenuIcon} alt="hamburger menu" />
                        </button>
                    </div>
                    <div className="column is-8 has-text-centered">
                        <Link to="/">
                            <img className="logo-type" src={Logo} alt="logo" />
                        </Link>
                    </div>
                </nav>
                <MobileMenu ref={el => (this.childMenu = el)} />
            </div>
        )
    }

    toggleMenu() {
        this.childMenu.open()
    }
}

export default NavbarMobileDark;
